import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ControlButtons from './ControlButtons';

function Menu({ currentUser, isVisible, setMenuIsVisible, logout }) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [showLanguageSwitcher, setShowLanguageSwitcher] = useState(false);

    function goToPage(url) {
        navigate(url);
    }

    function hide() {
        setMenuIsVisible(false);
        setShowLanguageSwitcher(false);
    }

    function setPreferredLanguage(lang) {
        i18n.changeLanguage(lang);
        setShowLanguageSwitcher(false);
    }

    return (<>
        {currentUser ?
            <nav className={'menu' + (isVisible ? ' show' : '')}>
                <button className="page-title page-title--light" onClick={hide}>{t('menu')}</button>

                <ControlButtons />

                {!showLanguageSwitcher ?
                    <ul className="menu__list">
                        <li><button onClick={() => { setShowLanguageSwitcher(true); }} type="button">{t('selectLanguage')}</button></li>
                        <li><button onClick={() => { hide(); goToPage('/profile'); }} type="button">{t('myProfile')}</button></li>
                        <li><button onClick={() => { hide(); logout(); goToPage('/'); }} type="button">{t('stop')}</button></li>
                    </ul>
                : null}

                {showLanguageSwitcher ?
                    <ul className="menu__select-language page-start__languages">
                        <li>
                            <button onClick={() => setPreferredLanguage('nl')} type="button" className="jsSelectLangBtn" data-lang="nl">
                                <div className="img"><img src="/assets/images/nl.png" alt="Nederlands" /></div>
                                <span>Nederlands</span>
                            </button>
                        </li>

                        <li>
                            <button onClick={() => setPreferredLanguage('de')} type="button" className="jsSelectLangBtn" data-lang="de">
                                <div className="img"><img src="/assets/images/de.png" alt="Deutsch" /></div>
                                <span>Deutsch</span>
                            </button>
                        </li>

                        <li>
                            <button onClick={() => setPreferredLanguage('en')} type="button" className="jsSelectLangBtn" data-lang="en">
                                <div className="img"><img src="/assets/images/en.png" alt="English" /></div>
                                <span>English</span>
                            </button>
                        </li>
                    </ul>
                : null}
            </nav>
        : null}
    </>);
}

export default Menu;