import { useRef, useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import QrScanner from 'qr-scanner';

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import ControlButtons from '../components/ControlButtons';

function PageScan({ settings, onSetCurrentUser, onSetCurrentCode }) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [showScanner, setShowScanner] = useState(true);

    const scannerEl = useRef(null);
    const manualCodeInputEl = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isUserFound, setIsUserFound] = useState(false);
    const [isInvalidCode, setIsInvalidCode] = useState(false);
    const [hasFinalResult, setHasFinalResult] = useState(false);
    const [scanner, setScanner] = useState(null);
    const [currentCamIndex, setCurrentCamIndex] = useState(0);

    const [manualCodeInput, setManualCodeInput] = useState('');

    const loadingIconStyle = { backgroundImuseCallbackage: `url('/assets/images/icon-loading.png')` };
    const successIconStyle = { backgroundImage: `url('/assets/images/icon-success.svg')` };
    const errorIconStyle = { backgroundImage: `url('/assets/images/icon-error.svg')` };

    const keyboard = useRef();
    const [activeInputEl, setActiveInputEl] = useState(null);
    const [keyboardLayout, setKeyboardLayout] = useState({
        default: ["1 2 3", "4 5 6", "7 8 9", "0", "{bksp}"]
    });

    const getUserByCode = useCallback((code) => {
        code = code.replace(/[^0-9]/g, '');

        if (!code) return;
        setIsLoading(true);

        fetch(settings.apiBaseUrl + 'get-visitor/' + code)
            .then(response => response.json())
            .then(data => {
                setIsLoading(false);

                if (data.visitor == null) userNotFound(data, code);
                else userFound(data.visitor);
            })
        ;

        function userFound(user) {
            setHasFinalResult(true);
            setIsUserFound(true);
            onSetCurrentUser(user);

            setTimeout(() => {
                navigate('/welcome');
            }, 2000);
        }

        function userNotFound(data, code) {
            if (data.is_valid) {
                onSetCurrentCode(code);
                navigate('/register');
            }
            else {
                showInvalidCodeMsg();
            }
        }
    }, [navigate, onSetCurrentCode, onSetCurrentUser, settings.apiBaseUrl]);

    const onCodeScanned = useCallback((result) => {
        if (isLoading || result === null) return;

        setIsLoading(true);
        setIsInvalidCode(false);
        getUserByCode(result);
    }, [isLoading, getUserByCode]);

    function onKeyboardChange(input) {
        setManualCodeInput(input);
    };

    function onKeyboardKeyPress(button) {
        if (button === '{shift}' || button === '{lock}') {
            setKeyboardLayout(keyboardLayout === 'default' ? 'shift' : 'default');
        }
    }

    function focusInputEl(inputEl) {
        setActiveInputEl(inputEl);
        keyboard.current.setInput(inputEl.value);
    }

    function showInvalidCodeMsg() {
        setIsInvalidCode(true);
    }

    function showEnterCodeManually() {
        setShowScanner(false);
        setIsLoading(false);
        setIsInvalidCode(false);

        setTimeout(() => {
            if (manualCodeInputEl.current !== null) manualCodeInputEl.current.focus();
        }, 500);
    }

    function validateManualCodeEntry() {
        if (isLoading) return;
        getUserByCode(manualCodeInput);
    }

    const setPreferredLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };

    function switchCamera() {
        if (scanner === null) return;

        QrScanner.listCameras(true).then(result => {
            const nextCamIndex = currentCamIndex + 1 <= result.length - 1 ? currentCamIndex + 1 : 0;

            scanner.setCamera(result[nextCamIndex].id);
            setCurrentCamIndex(nextCamIndex);
        });
    };

    useEffect(() => {
        if (isLoading || hasFinalResult) return;
        if (!scannerEl.current) return;

        let scanObj = null;

        const timeout = setTimeout(() => {
            scanObj = new QrScanner(scannerEl.current, result => onCodeScanned(result.data), { returnDetailedScanResult: true });
            scanObj.start();

            setScanner(scanObj);
        }, 2000);

        return () => {
            clearTimeout(timeout);
            if (scanObj) {
                scanObj.destroy();
                setScanner(null);
            }
        }
    }, [onCodeScanned, hasFinalResult, isLoading]);

    return (
        <div className="page-scan">
            <button className="page-title" onClick={switchCamera}>{t('signIn')}</button>

            <ControlButtons />

            <ul className="page-scan__languages">
                <li>
                    <button onClick={() => setPreferredLanguage('nl')} type="button" className={ 'jsSelectLangBtn' + (i18n.language === 'nl' ? ' current' : '') } data-lang="nl">
                        <div className="img"><img src="/assets/images/nl.png" alt="Nederlands" /></div>
                    </button>
                </li>

                <li>
                    <button onClick={() => setPreferredLanguage('de')} type="button" className={ 'jsSelectLangBtn' + (i18n.language === 'de' ? ' current' : '') } data-lang="de">
                        <div className="img"><img src="/assets/images/de.png" alt="Deutsch" /></div>
                    </button>
                </li>

                <li>
                    <button onClick={() => setPreferredLanguage('en')} type="button" className={ 'jsSelectLangBtn' + (i18n.language === 'en' ? ' current' : '') } data-lang="en">
                        <div className="img"><img src="/assets/images/en.png" alt="English" /></div>
                    </button>
                </li>
            </ul>

            <div className="page-scan__left">
                <div className="scan-instruction">
                    <div className="scan-instruction__img"><img src="/assets/images/profile-avatar-2.svg" alt="Scan je QR-code" /></div>
                    <span className="scan-instruction__title">{t('startScan')}</span>
                    <span className="scan-instruction__subtitle">{t('scanForgotPass')}</span>
                </div>
            </div>

            <div className="page-scan__right">
                {showScanner &&
                    <div className="page-scan__scan-wrapper scanning">
                        <div className="page-scan__video-wrapper">
                            <video className="page-scan__scanner" ref={scannerEl}></video>
                            <svg className="page-scan__video-overlay scan-region-highlight-svg" viewBox="0 0 238 238" preserveAspectRatio="none"><path d="M31 2H10a8 8 0 0 0-8 8v21M207 2h21a8 8 0 0 1 8 8v21m0 176v21a8 8 0 0 1-8 8h-21m-176 0H10a8 8 0 0 1-8-8v-21"></path></svg>

                            {isLoading ? <div className="page-scan__video-loading-icon"><div style={loadingIconStyle}></div></div> : null}
                            {isUserFound ? <div className="page-scan__video-success-icon" style={successIconStyle}></div> : null}
                            {isInvalidCode ? <div className="page-scan__error-icon" style={errorIconStyle}></div> : null}
                        </div>

                        <div className="page-scan__start-scanning-msg">
                            <p>{isInvalidCode ? t('scanPassErrorMsg') : t('scanPassInstruction')}</p>
                        </div>

                        <button className="page-scan__enter-code" onClick={showEnterCodeManually}>{t('scanHavingProblemsInstruction')}</button>
                    </div>
                }

                <div className={cn('page-scan__code-entry-wrapper code-entry-wrapper', !showScanner && 'show')}>
                    <input type="text" value={manualCodeInput} onFocus={(e) => { focusInputEl(e.target); }} ref={manualCodeInputEl} onInput={e => setManualCodeInput(e.target.value)} className={'code-entry-wrapper__input input' + (focusInputEl ? ' focus' : '')} placeholder={t('myCode')} />
                    <button onClick={validateManualCodeEntry} className="code-entry-wrapper__submit-btn side-btn">{t('continue')}</button>
                </div>
            </div>

            <div className={'keyboard-wrapper keyboard-wrapper--numeric' + (activeInputEl ? ' show' : '')}>
                <Keyboard keyboardRef={r => { keyboard.current = r }} onChange={onKeyboardChange} onKeyPress={onKeyboardKeyPress} layout={keyboardLayout} />
            </div>
        </div>
    );
}

export default PageScan;
