import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

function PageRegister({ settings, currentCode, onSetCurrentUser }) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [formIsDisabled, setFormIsDisabled] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    
    const keyboard = useRef();
    const [activeInputEl, setActiveInputEl] = useState(null);
    const [activeInputName, setActiveInputName] = useState(null);

    const [keyboardLayout, setKeyboardLayout] = useState('default');
    const excludeFromKeyboardLayout = { default: ['{tab}', '{enter}', '{lock}'], shift: ['{tab}', '{enter}', '{lock}'] };

    useEffect(() => {
        // Make sure we have a code
        if (currentCode === null) {
            return navigate('/');
        }
    }, [currentCode, navigate]);

    function submitForm(e) {
        e.preventDefault();

        const formData = new FormData(e.target);
        formData.append('code', currentCode);

        setFormIsDisabled(true);

        fetch(settings.apiBaseUrl + 'register-visitor', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                if (!data.success) {
                    setFormIsDisabled(false);

                    console.error(data.err);
                    alert(data.err_msg);
                    return;
                }

                // Set the current user and redirect
                onSetCurrentUser(data.user);
                return navigate('/welcome');
            })
        ;
    }

    function onKeyboardChange(input) {
        activeInputEl.value = input;
    };

    function onKeyboardKeyPress(button) {
        if (button === '{shift}' || button === '{lock}') {
            setKeyboardLayout(keyboardLayout === 'default' ? 'shift' : 'default');
        }
    }

    function focusInputEl(inputEl) {
        setActiveInputEl(inputEl);
        keyboard.current.setInput(inputEl.value);
    }

    function changeLanguage(lang) {
        i18n.changeLanguage(lang);
        setSelectedLanguage(lang);
    }

    return (<>
        <div className="page-profile">
            <button className="page-title">{t('register')}</button>

            <div className="page-profile__left">
                <div className="profile-welcome">
                    <div className="profile-welcome__top-wrapper">
                        <div className="profile-welcome__img"><img src="/assets/images/profile-avatar.svg" alt="Profile avatar" /></div>
                    </div>

                    <span className="profile-welcome__name profile-welcome__name--small">
                        {t('registerIntro')}
                    </span>
                </div>
            </div>

            <div className="page-profile__right">
                <form className="page-profile__registration-form" onSubmit={submitForm}>
                    <fieldset disabled={formIsDisabled}>
                        <div className="input-wrapper">
                            <input name="first_name" type="text" onFocus={(e) => { focusInputEl(e.target); setActiveInputName('first_name') }} className={'input' + (activeInputName === 'first_name' ? ' focus' : '')} placeholder={t('firstName')} />
                        </div>

                        <div className="input-wrapper">
                            <input name="last_name" type="text" onFocus={(e) => { focusInputEl(e.target); setActiveInputName('last_name') }} className={'input' + (activeInputName === 'last_name' ? ' focus' : '')} placeholder={t('lastName')} />
                        </div>

                        <div className="input-wrapper">
                            <input name="email" type="email" onFocus={(e) => { focusInputEl(e.target); setActiveInputName('email') }} className={'input' + (activeInputName === 'email' ? ' focus' : '')} placeholder={t('email')} />
                        </div>

                        <div className="input-wrapper">
                            <div className="lang-wrapper">
                                <span>{t('preferredLanguage')}</span>

                                <input checked={selectedLanguage === 'nl'} onChange={() => changeLanguage('nl')} id="pref-lang-nl" name="preferred_language" value="nl" type="radio" />
                                <label htmlFor="pref-lang-nl"><img src="/assets/images/nl.png" alt="Language NL" /></label>

                                <input checked={selectedLanguage === 'de'} onChange={() => changeLanguage('de')} id="pref-lang-de" name="preferred_language" value="de" type="radio" />
                                <label htmlFor="pref-lang-de"><img src="/assets/images/de.png" alt="Language DE" /></label>

                                <input checked={selectedLanguage === 'en'} onChange={() => changeLanguage('en')} id="pref-lang-en" name="preferred_language" value="en" type="radio" />
                                <label htmlFor="pref-lang-en"><img src="/assets/images/en.png" alt="Language EN" /></label>
                            </div>
                        </div>

                        <div className="input-wrapper">
                            <input name="licence_plate" type="text" onFocus={(e) => { focusInputEl(e.target); setActiveInputName('licence_plate') }} className={'input' + (activeInputName === 'licence_plate' ? ' focus' : '')} placeholder={t('licencePlate')} />
                        </div>

                        <button type="submit" className="page-profile__continue-btn side-btn">{t('continue')}</button>
                    </fieldset>
                </form>
            </div>

            <div className={'keyboard-wrapper' + (activeInputEl ? ' show' : '')}>
                <Keyboard keyboardRef={r => { keyboard.current = r }} onChange={onKeyboardChange} onKeyPress={onKeyboardKeyPress} layoutName={keyboardLayout} excludeFromLayout={excludeFromKeyboardLayout} />
            </div>
        </div>
    </>);
}

export default PageRegister;
